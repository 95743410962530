'use strict';

angular.module('cpformplastApp')
  .directive('numpad', () => ({
    templateUrl: 'components/numpad/numpad.html',
    restrict: 'E',
    controller: 'NumpadController',
    controllerAs: 'numpad',
    scope: {
      number: '=number',
      show: '=show'
    }
  }));
